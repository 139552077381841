<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-1">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-1">
					{{ translate('register') }}
				</div>
			</div>
			<div
				v-if="positionData !== ''"
				class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('parent') }}: </span>
						<span>{{ positionData.parent }} </span>
						<span>({{ translate('leg') }}: {{ translate(positionData.leg) }})</span>
					</div>
				</div>
			</div>
		</div>
		<template>
			<div class="row custom-sticky">
				<div
					v-if="Object.keys(activePackage).length"
					class="col-12 mb-4">
					<b-progress
						:max="activePackage.attributes.volume_required"
						:animated="true"
						variant="primary"
						height="30px"
						striped
						class="mt-auto">
						<b-progress-bar :value="totalVolume">
							<strong style="width: 100%; position: absolute; font-size: 1.7em;">
								<span class="text-dark bg-light h-100 rounded px-3">
									{{ totalVolume }} / {{ activePackage.attributes.volume_required }} {{ translate('bv_added') }}
								</span>
							</strong>
						</b-progress-bar>
					</b-progress>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-5 col-12">
					<ul
						v-if="!loadingMemberProducts || !loadingPackages"
						class="list-group w-100 h-100">
						<template v-if="hasMemberProducts">
							<li
								v-for="item in memberProducts"
								:key="item.id"
								class="list-group-item bg-light">
								<div class="row">
									<div class="col-12 col-md-7">
										<div class="h5 my-2 font-weight-normal">
											{{ translate('activation_product') }}
										</div>
									</div>
									<div class="col-12 col-md-5 d-flex">
										<div class="my-auto ml-auto text-right">
											<span class="h5">{{ item.attributes.price }}</span><br>
											<p
												v-if="item.attributes.exchange"
												class="exchange-text small">
												{{ item.attributes.exchange_price }}*
											</p>
										</div>
									</div>
									<div class="col-12">
										<div
											class="text-muted text-justify font-weight-normal"
											v-html="translate('activation_product_message')" />
									</div>
								</div>
							</li>
						</template>
						<template>
							<li class="list-group-item package-ranks">
								<div class="h5 my-2 font-weight-normal">
									{{ translate('select_your_package') }}
								</div>
							</li>
							<li
								v-for="item in packages"
								:key="item.id"
								:class="activePackageId === item.id ? 'active' : ''"
								class="list-group-item pointer package-ranks"
								@click="!loadingProducts ? fillInitialProducts(item.id) : ''">
								<div class="row mx-0 pointer package-ranks">
									<div class="col-3 p-0">
										<img
											:src="item.attributes.image"
											width="50"
											class="img-fluid">
									</div>
									<div class="col-6 h5 my-auto text-center">
										<div>
											{{ translate(item.attributes.code_name) }}
										</div>
									</div>
									<div class="col-3 h5 my-auto pr-0">
										<div>
											{{ translate('bvs', {qty: item.attributes.volume_required}) }}
										</div>
									</div>
								</div>
								<div class="row mx-0 pointer">
									<div class="col-12 px-2">
										<div class="my-auto text-center">
											<span>
												<small
													v-if="getStoredCountry() === 'US'"
													class="text-muted font-italic">
													{{ translate(`${item.attributes.code_name}_price_book`) }}
												</small>
												<small
													v-else
													class="text-muted font-italic">
													{{ translate(`${item.attributes.code_name}_price`) }}
												</small>
											</span>
										</div>
									</div>
								</div>
							</li>
							<li
								class="list-group-item pointer"
								@click="prepareForStorage(true)">
								<div class="row">
									<div class="col h5 mb-0">
										<div class="p-3 text-center mb-0">
											{{ translate('continue_without_product') }}
										</div>
									</div>
								</div>
							</li>
							<li
								v-if="['lg', 'xl'].includes(windowWidth)"
								class="list-group-item h-100" />
						</template>
					</ul>
					<ul
						v-else
						class="p-0">
						<li class="list-group-item w-100 h100 fade-in text-center d-flex justify-content-center align-items-center">
							<div class="row h-100 w-100">
								<div class="col-12">
									<div class="h2 py-3">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="col-lg-7 col-12 mt-3 mt-lg-0">
					<ul class="list-group w-100 h-100">
						<li
							v-if="!loadingProducts"
							class="list-group-item h-100">
							<ul class="list-group w-100 mb-3">
								<li class="list-group-item">
									<div class="row">
										<div class="col-12 d-flex">
											<div class="text-justify">
												<p class="h4">
													{{ translate('level_content_title', { levelName: translate(activePackage.attributes.code_name) } ) }}
												</p>
												<span>
													<p
														v-if="getStoredCountry() === 'US'"
														class="mb-0"
														v-html="translate(`${activePackage.attributes.code_name}_description_book`)" />
													<p
														v-else
														class="mb-0"
														v-html="translate(`${activePackage.attributes.code_name}_description`)" />
												</span>
												<!-- <p
													v-if="['JP', 'CA'].includes(getStoredCountry())"
													class="mt-3"
													v-html="translate(`account_activation`, { number: packagesActivationWeeks[getStoredCountry()] ? packagesActivationWeeks[getStoredCountry()][activePackage.attributes.code_name] : packagesActivationWeeks.default[activePackage.attributes.code_name] })" /> -->
											</div>
										</div>
										<!-- <div
											v-if="showBonusOptions"
											class="col-12 mt-3">
											<label
												for="options"
												class="font-weight-bold">
												{{ translate('select_option') }}
											</label>
											<select
												id="options"
												v-model="selectedBonusOption"
												type="text"
												name="options"
												class="form-control">
												<option
													v-for="(item, index) in bonusOptions[activePackage.attributes.code_name]"
													:key="index"
													:value="item">
													{{ translate(item) }}
												</option>
											</select>
										</div> -->
									</div>
								</li>
							</ul>
							<ul class="list-group w-100">
								<template v-if="hasProducts">
									<template>
										<li
											v-for="item in products"
											:key="item.key"
											class="list-group-item p-0">
											<div class="row">
												<div class="col-12 col-sm-3 d-flex">
													<div class="my-auto mx-auto">
														<img
															:src="item.attributes.thumbnail"
															class="img-fluid float-right">
													</div>
												</div>
												<div class="col-12 col-sm-3 d-flex p-0">
													<div
														:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2 mx-auto text-center':'my-auto'">
														<span class="font-weight-bolder">{{ translate(item.attributes.code_name) }}</span><br>
														<div>
															{{ translate('bvs', {qty: (item.attributes.bvs)}) }}
														</div>
													</div>
												</div>
												<div class="col-12 col-sm-3 d-flex">
													<div
														:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2':'my-auto'"
														class="mx-auto">
														<spinner
															v-model="purchase[item.attributes.sku]"
															:min="0"
															:max="99"
															:value="purchase[item.attributes.sku]"
															:button-class="'vnis__button bg-primary-alt'"
															:input-class="'vnis__input vnis-input-custom-width'"
															:integer-only="true"
															:mouse-down-speed="500"
															style="min-width:120px;max-width:160px" />
													</div>
												</div>
												<div class="col-12 col-sm-3 d-flex">
													<div
														:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2':'my-auto'"
														class="mx-auto text-right">
														<span class="font-weight-bolder h5">{{ item.attributes.price }}</span><br>
														<p
															v-if="item.attributes.exchange"
															class="exchange-text small">
															{{ item.attributes.exchange_price }}*
														</p>
													</div>
												</div>
											</div>
										</li>
										<p class="pt-3 text-right h2">
											{{ translate('subtotal') }} {{ price_format.replace('[price]', formatPriceNumber(subtotal)) }}
											<span
												v-if="thereIsExchange"
												class="font-weight-normal exchange-text h4"
												style="display: block; font-size:14px;">
												{{ exchange_price_format.replace('[price]', formatPriceNumber(exchange_subtotal)) }}*
											</span>
										</p>
										<p
											v-if="thereIsExchange"
											class="exchange-text text-right small mt-2">
											{{ translate('exchange_disclaimer') }}
										</p>
									</template>
								</template>
								<li
									v-if="errors"
									class="mt-3 list-unstyled">
									<b-alert
										variant="warning"
										show>
										<span
											v-for="(error, key) in errors"
											:key="key">
											<i class="fa fa-exclamation-circle" />
											{{ error }} <br>
										</span>
									</b-alert>
								</li>
							</ul>
						</li>
						<li
							v-else
							class="list-group-item w-100 h100 fade-in text-center d-flex justify-content-center align-items-center">
							<div class="row h-100 w-100">
								<div class="col-12">
									<div class="h2 py-3">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div
					v-if="Object.keys(activePackage).length"
					class="col-12 mt-4">
					<b-progress
						:max="activePackage.attributes.volume_required"
						:animated="true"
						variant="primary"
						height="30px"
						striped
						class="mt-auto">
						<b-progress-bar :value="totalVolume">
							<strong style="width: 100%; position: absolute; font-size: 1.7em;">
								<span class="text-dark bg-light h-100 rounded px-3">
									{{ totalVolume }} / {{ activePackage.attributes.volume_required }} {{ translate('bv_added') }}
								</span>
							</strong>
						</b-progress-bar>
					</b-progress>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-4 ml-auto my-4">
					<b-button
						:disabled="validating || !!preparing || loadingProducts || loadingPackages"
						variant="primary"
						size="lg"
						block
						class="float-right"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import {
	promoter,
	influencer,
	ambassador,
	PACKAGES_ACTIVATION_WEEKS as packagesActivationWeeks,
} from '@/settings/Products';
import WindowSizes from '@/mixins/WindowSizes';
import DocumentTitle from '@/mixins/DocumentTitle';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';

export default {
	name: 'RegisterOldProduct',
	mixins: [CommonMix, PackMix, DocumentTitle, WindowSizes],
	data() {
		return {
			promoter,
			influencer,
			ambassador,
			packagesActivationWeeks,
			selectedBonusOption: 'option_1',
		};
	},
	computed: {
		thereIsExchange() {
			const theresExchange = this.products.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		errors() {
			return this.validationErrors[`${this.steps[this.$route.name].validation}.products`];
		},
		// showBonusOptions() {
		// 	return [promoter, influencer, ambassador].includes(this.activePackage.attributes.code_name);
		// },
		hasBonusOptions() {
			return [promoter, influencer, ambassador].includes(this.activePackage.attributes.code_name);
		},
	},
	watch: {
		country() {
			this.memberProd.getMembership(this.getStoredCountry());
			this.loadPackageInfo();
			this.loadProductsInfo();
		},
	},
	created() {
		this.memberProd.getMembership(this.getStoredCountry());
		this.loadPackageInfo();
		this.loadProductsInfo();
		this.getStoredInformation();
	},
	methods: {
		getStoredInformation() {
			const { product } = { ...this.getStepInformation() };
			if (product && product.bonus_option) this.selectedBonusOption = product.bonus_option;
		},
		prepareForStorage(noProducts) {
			this.clearValidationError(true);
			this.preparing = true;
			const products = {};
			let payload = {};
			payload.product = { package_code_name: '' };
			if (!noProducts) {
				Object.keys(this.purchase).forEach((sku) => {
					if (sku !== '' && this.purchase[sku] > 0) {
						products[sku] = this.purchase[sku];
					}
				});
				payload.product = { package_code_name: this.activePackage.attributes.code_name };
			}
			payload.product = { products, ...payload.product };
			if (this.hasBonusOptions) {
				payload.product = { bonus_option: this.selectedBonusOption, ...payload.product };
			}
			payload = { step: this.steps[this.$route.name].validation, ...payload };
			this.validateStep(payload).then((response) => {
				const { cart_id: cartId } = response.response;
				this.saveCart(cartId);
				this.saveStep(payload);
			}).catch(() => {}).finally(() => { this.preparing = false; });
		},
	},
};
</script>
